import React from "react";
import PropTypes from 'prop-types';
// 
import { Tooltip } from 'antd';
// 
import './Tooltip.scss';

const AirTooltip = ({ text }) => {
    return (
        <Tooltip title={text}>
            <i className="icon icon-tooltip"></i>
        </Tooltip>
    );
}

AirTooltip.propTypes = {
    text: PropTypes.string
}
export default AirTooltip
