import axios from "axios";
// import jwtDecode from "jwt-decode"

const apiUrl = process.env.REACT_APP_API_URL

const api = axios.create({
    baseURL: apiUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
});

api.interceptors.request.use(
    async (config) => {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken) {    config.headers.Authorization = `Bearer ${accessToken}`
        } else {
            window.location.href = "https://connect.airscore.app/";
        }
        return config;
    },
    (error) => {
        return Promise.error(error)
    }
);

api.interceptors.response.use(undefined, (err) => {
    const customError = {
        name: "Api Error",
        message: err.response && err.response.status < 500 ? err.response.data.msg : "Erro no sistema, tente mais tarde!",
        data: err.response ? err.response.data : null
    };
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    return Promise.reject(customError)
})

export default api;