import { configureStore } from '@reduxjs/toolkit';
// 
import { home } from '../features/Home/HomeSlice';
import { user } from './AppSlice';
import alertReducer from '../common/components/Alert/alertSlice';

export const configStore = () => {
    return configureStore({
        reducer: {
            // to alert
            alert: alertReducer,

            // user
            user: user.reducer,

            // home
            home: home.reducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    })
}