import React from 'react';
import PropTypes from 'prop-types'
// 
import './Alert.scss';

function AirAlert(props) {
  const icons = {
    'success': <i className="icon icon-check"></i>,
    'error': <i className="icon icon-close"></i>,
    'warning': <i className="icon icon-alert"></i>,
    'info': <i className="icon icon-info"></i>,
  }

  const type = props.type ?? "success";

  return(
    <div className="container-modal" style={{ display: 'none'}}>
      <div className={`container-icon container-` + type} >{icons[type]}</div>

      <div className="container-content">
        <h2 className={`title title-` + type}>{props.title ?? type}!</h2>
        
        { 
          props.text &&
          <p className="text">{props.text}</p>
        }

        {
          props.question &&
          <p className="question">{props.question}</p>
        }

        <div className="buttons">
          {/* {
            props.cancelText && props.onCancel && 
            <AirButton 
              color="grey"
              outline={true}
              text={props.cancelText ?? "Cancel"} 
              onClick={props.onCancel}
            /> 
          }
          {
            props.okText && props.onOk && 
            <AirButton 
              text={props.okText ?? "Ok"} 
              onClick={() => {
                props.onOk();
                setTimeout(() => {
                  props.onCancel();
                }, 200)
              }}
              color="danger"
            /> 
          }  */}
        </div>
      </div>
    </div>
  );
}

AirAlert.propTypes = {
  type: PropTypes.string,
  show: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  question: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string
}

export default AirAlert;