import React from 'react';
import PropTypes from 'prop-types'
// 
import "./ProgressBar.scss";

const ProgressBar = ({ value }) => {
    return (
        <div className="bar-progress">
            <div
                className="progress-label"
                style={{ left: `${((value > 1000 ? 1000 : (value < 0 ? 0 : value)) / 1000) * 100}%` }}
            >
                {value}
            </div>

            <div className="divisors"></div>


            <div className="progress-bar">
                <div className="progress-value" style={{ width: `${(value > 1000 ? 1000 : (value < 0 ? 0 : value)) / 10}%` }}></div>
            </div>
        </div>
    )
}

ProgressBar.propTypes = {
    value: PropTypes.number,
}

export default ProgressBar;