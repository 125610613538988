import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
// 
import "./EngagementRating.scss"

const EngagementRating = ({
    engagement,
}) => {
    const { t } = useTranslation()
    // 
    const [classification, updateClassification] = useState()
    // 
    const labels = {
        1: t("20% worse than others like you"),
        2: t("Equal others like you"),
        3: t("75% better than others like you"),
    }

    const buildClassification = () => {
        if (engagement <= 33) return 1
        if (engagement > 33 && engagement < 66) return 2
        if (engagement >= 66) return 3
    }

    useEffect(() => {
        updateClassification(buildClassification())
    }, [engagement])

    return <div className="engagement-rating">
        <span>{labels[classification]}</span>
        
        <div className="columns">
            <div className={`column ${classification > 0 ? "active" : ""}`}></div>
            <div className={`column ${classification > 1 ? "active" : ""}`}></div>
            <div className={`column ${classification > 2 ? "active" : ""}`}></div>
        </div>
    </div>
}

EngagementRating.defaultProps = {
    engagement: 0,
    network: "instagram"
}

EngagementRating.propTypes = {
    network: PropTypes.string,
    engagement: PropTypes.any
}

export default EngagementRating