import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// 
import StateStatus from '../utils/status';

const initialState = {
    user: {},

    status: StateStatus.idle,
}


export const fetchUser = createAsyncThunk('user', async () => {
    let user;

    user = {
        name: "Cristiano Ronaldo",
        username: "@cristiano",
        network: "instagram",
    }

    return user
})

export const user = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [fetchUser.pending]: (state) => {
            state.status = StateStatus.loading
        },
        [fetchUser.fulfilled]: (state, action) => {
            state.user = action.payload
            
            state.status = StateStatus.succeeded
        },
        [fetchUser.rejected]: (state) => {
            state.status = StateStatus.failed
        },
    }
})

export const selectUser = (state) => state.user

export default user.reducer
