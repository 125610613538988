export class Formatters {
    addDots(number) {
        number += "";
        
        let x = number.split(".");
        let x1 = x[0];
        let x2 = x.length > 1 ? "." + x[1] : "";
        
        let regex = /(\d+)(\d{3})/;
        
        while (regex.test(x1)) {
            x1 = x1.replace(regex, "$1.$2"); //  no-useless-concat
        }
        return x1 + x2;
    }
}