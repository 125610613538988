import i18n from "i18next";
import {
    initReactI18next
} from "react-i18next";

import pt from "./pt";
import en from "./en";
import span from "./span";

const resources = {
    pt: {
        translation: pt
    },
    en: {
        translation: en
    },
    span: {
        translation: span
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "pt",

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;