import React from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
// 
import { Progress } from 'antd';
// 
import "./ProgressCircle.scss";
import AirTooltip from '../Tooltip/Tooltip';

const ProgressCircle = ({ value }) => {
    const { t } = useTranslation();
  
    return (
        <div className="circle-progress">
            <div className="info-progress">
                <p className="info">Airscore
                    <AirTooltip text={t("O Airscore é uma métrica exclusiva que indica o grau de influência que você exerce sobre a sua base de seguidores. Ele leva em consideração o tamanho da sua base, quantidade de posts e interações geradas para calcular uma nota de 0 a 1000 para a qualidade do seu engajamento.")}></AirTooltip>
                </p>
                <span className="progress">{Math.floor(value)}</span>
            </div>
            <Progress 
                width={150}
                type="circle" 
                status="normal"
                showInfo={false}
                percent={value / 10} 
                strokeColor="#b924ad" 
                trailColor="#DEDEDE"
            />
        </div>
    )
}

ProgressCircle.propTypes = {
    value: PropTypes.number,
}

export default ProgressCircle;