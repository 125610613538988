import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// 
import Home from "./Home/Home";

function Routes() {
    return (
        <HashRouter>
            <Switch>
                <Route exact path={["/:token", "/#/:token", "/", "/#"]} component={Home} />
            </Switch>
        </HashRouter>
    );
}

export default Routes;