import React from 'react';
import PropTypes from 'prop-types';
// 
import { Tooltip } from 'antd';
import { useTranslation } from "react-i18next";
// 
import './GrowOrDecrease.scss';


const GrowOrDecrease = ({ changePercent, icon = true, tooltip = false }) => {
    const { t } = useTranslation();

    return (
        <div className={`grow-decrease ${changePercent < 0 ? "fall" :
            (changePercent > 0) ? "growth" :
                "normal"}
        `}>
            <span className="performance-text">{t(changePercent > 1 ? "crescimento" : (changePercent < 0 ? "quedra" : ""))}</span>

            {changePercent !== null &&
                <div className="style-value">
                    <Tooltip title={tooltip !== false && t("Desempenho")}>
                        {changePercent === 0 ? "0" : (changePercent > 1 ? `+${changePercent}` : `${changePercent}`)}%
                    </Tooltip>

                    {icon ?
                        (changePercent < 0 ?
                            <i className="icon icon-fall"></i> :
                            (changePercent > 0) ?
                                <i className="icon icon-growth"></i> :
                                <></>
                        ) :
                        null
                    }
                </div>
            }
        </div>
    )
}

GrowOrDecrease.propTypes = {
    icon: PropTypes.bool,
    tooltip: PropTypes.bool,
    value: PropTypes.string,
    changePercent: PropTypes.any
}

export default GrowOrDecrease