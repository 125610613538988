import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// 
import { useSelector } from 'react-redux';
import { selectData } from "./HomeSlice"
import StateStatus from '../../utils/status';
// 
import AirTooltip from '../../common/components/Tooltip/Tooltip';
import Loading from '../../common/components/Loading/Loading'
import ProgressBar from '../../common/components/ProgressBar/ProgressBar';
import ProgressCircle from '../../common/components/ProgressCircle/ProgressCircle';
import GrowOrDecrease from '../../common/components/GrowOrDecrease/GrowOrDecrease';
import EngagementRating from '../../common/components/EngagementRating/EngagementRating';
// 
import { Formatters } from '../../utils/formatters';
import './Home.scss';

function Home() {
  const { t } = useTranslation();

  const homeObj = useSelector(selectData);

  const [selectedFilter, setSelectedFilter] = useState("atual");

  const formatters = new Formatters();

  const filters = [
    { alias: "atual", label: t("Current") },
    { alias: "seven_days", label: t("7 days") },
    { alias: "thirty_days", label: t("30 days") },
  ];

  return (
    <div className="home-page pattern-page">
      {(homeObj.status === StateStatus.succeeded && homeObj.data.performance) ? <>
        <div className="home-content">
          {/* AIRSCORE */}
          <div className="card-airscore card-pattern">
            <h2 className="card-title">{t("Airscore")}</h2>

            <div className="gauge">
              <ProgressCircle
                value={homeObj.data.airscore}
              />
            </div>

            <p className="title-filter">{t("Performance evolution")}</p>
            <div className="tab-filters">
              {filters.map((filter, index) => {
                return <div
                  key={index}
                  onClick={() => setSelectedFilter(filter.alias)}
                  className={`filter ${selectedFilter === filter.alias ? "active" : ""}`}
                  style={{ width: `${100 / filters.length}%` }}
                >
                  {filter.label}
                </div>
              })}
            </div>

            <div className="airscore-changes">
              <div className="ac-top">
                <span className="ac-text">{t("Airscore")}</span>
                {homeObj.data.performance[selectedFilter].performanceAirscore ?
                  <GrowOrDecrease changePercent={homeObj.data.performance[selectedFilter].performanceAirscore} />
                  : <span className="empty">{t("no data")}</span>
                }
              </div>

              <div className="ac-middle">
                {homeObj.data.performance[selectedFilter].airscore ?
                  <ProgressBar value={homeObj.data.performance[selectedFilter].airscore} />
                  : <span className="empty">{t("no data")}</span>
                }
              </div>

              <div className="ac-bottom">
                <span className="ac-text">{t("Followers")}</span>
                {homeObj.data.performance[selectedFilter].followers ?
                  <GrowOrDecrease changePercent={homeObj.data.performance[selectedFilter].followers} />
                  : <span className="empty">{t("no data")}</span>
                }
              </div>
            </div>
          </div>

          {homeObj.data.interactions && Object.keys(homeObj.data.interactions).map((interaction, index) => {
            return <div className={`card-${interaction} card-pattern card-interactions`} key={index}>
              <h2 className="card-title">{t(interaction)}</h2>

              {Object.keys(homeObj.data.interactions[interaction]).map((value, i) => {
                let engagement = window.localStorage.getItem(`${interaction}_${value}`)

                if(!engagement) {
                  engagement = Math.floor(Math.random() * 100) + 1

                  window.localStorage.setItem(`${interaction}_${value}`, engagement)
                }

                return <div className="card-value" key={i}>
                  <h3 className="title-value-card">{t(`${interaction}_${value}`)}</h3>

                  {homeObj.data.interactions[interaction][value] != null ? <>
                      <div className={`icon-value color-${interaction}`}>
                        <i className={`icon icon-${interaction}`}></i>
                        <span className="quant">{formatters.addDots(homeObj.data.interactions[interaction][value])}</span>
                      </div>

                      <EngagementRating 
                          engagement={engagement}
                      />
                    </>
                    : <div className="icon-value icon-value-empty">
                      <span className="empty">{t("in process")}...</span>
                      <AirTooltip text={t("The data in question is being metrified. Within 24 hours the value will be available in the system.")}></AirTooltip>
                    </div>
                  }
                </div>
              })}
            </div>
          })}
        </div>
      </> : (homeObj.status === StateStatus.failed ? "ERROR" : <Loading />)
      }
    </div>
  );
}

export default Home;
