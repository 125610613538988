const en = { 
    views: "Views",
    comments: "Comments",
    likes: "Likes",
    hashtags: "Hashtags",

    views_total: "Total views",
    views_average: "Average views",
    comments_total: "Total comments",
    comments_average: "Average comments",
    likes_total: "Total likes",
    likes_average: "Average of likes",
    hashtags_total: "Total hashtags",
    hashtags_average: "Average hashtags",
}

export default en;