import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from "react";
import { selectObjAlert } from '../common/components/Alert/alertSlice';
// 
import AirAlert from '../common/components/Alert/Alert';
import Header from '../common/components/Header/Header';
import Loading from "../common/components/Loading/Loading";
// 
import { selectData, fetchData } from "../features/Home/HomeSlice"
import Routes from '../features/Routes';
import StateStatus from "../utils/status";

function App() {
    const dispatch = useDispatch();
    const homeObj = useSelector(selectData);
    // 
    const query = new URLSearchParams(window.location.href.split("?")[1])
    const nw = query.get("nw")
    
    useEffect(() => {
        const url = window.location.hash.replace("#/", "")
        const acToken = url.split("?")[0]

        if(acToken !== null && acToken !== "") {
            window.localStorage.setItem("accessToken", acToken)
        }
        
        if (homeObj.status === StateStatus.idle) {
            dispatch(fetchData((nw === "fb" || "facebook") ? acToken : null))
        }
    }, [])
    
    useEffect(() => {
        if (homeObj && (!homeObj.data.analyticsComplet || !homeObj.data.searchComplet) && homeObj.status === StateStatus.succeeded) {
            const url = window.location.hash.replace("#/", "")
            const acToken = url.split("?")[0]

            setTimeout(() => {
                dispatch(fetchData((nw === "fb" || "facebook") ? acToken : null));
            }, 10000);
        }
      }, [homeObj.status]);
    
    return <>
        {homeObj.status === StateStatus.succeeded ? <>
            <Header user={{ username: homeObj.data.username }} />

            <Routes />

        </> : (homeObj.status === StateStatus.failed ? "ERRO" : <Loading size="large" />)
        }

        <AirAlert
            show={useSelector(selectObjAlert).show}
            type={useSelector(selectObjAlert).type}
            title={useSelector(selectObjAlert).title}
            text={useSelector(selectObjAlert).text}
            question={useSelector(selectObjAlert).question}
            okText={useSelector(selectObjAlert).okText}
            cancelText={useSelector(selectObjAlert).cancelText}
            onOk={useSelector(selectObjAlert).onOk ?? function () { }}
            onCancel={useSelector(selectObjAlert).onCancel ?? function () { }}
        />
    </>
}

export default App;