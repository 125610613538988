import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// 
import HomeData from "../../models/HomeData"
import getData, { getDataWithId } from "./HomeService"
import StateStatus from '../../utils/status';

const initialState = {
    data: new HomeData(),

    status: StateStatus.idle,
}


export const fetchData = createAsyncThunk('home/interactions', async (id) => {
    const obj = new HomeData();
    
    await (id ? getDataWithId(id) : getData()).then((res) => {
        obj.fromJson(res.data);
    }).catch((err) => {
        console.log("err");
        console.log(err);
    });

    return obj;
})

export const home = createSlice({
    name: 'home',
    initialState: initialState,
    reducers: {
        loadPerformanceStatus: (state) => {
            state.performanceStatus = StateStatus.loading
        }
    },
    extraReducers: {
        [fetchData.pending]: (state) => {
            state.status = StateStatus.loading
        },
        [fetchData.fulfilled]: (state, action) => {
            state.data = action.payload

            state.status = StateStatus.succeeded
        },
        [fetchData.rejected]: (state) => {
            state.status = StateStatus.failed
        },

    }
})

export const { loadPerformanceStatus } = home.actions

export const selectData = (state) => state.home

export default home.reducer
