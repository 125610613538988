import React from 'react';
import PropTypes from 'prop-types'
import { Spin } from 'antd';
// 
import "./Loading.scss"

const Loading = ({ size = "middle", width, height }) => {
    return <div className="container-loading" size={{ width, height }}>
        <Spin size={size} />
    </div>
}

Loading.propTypes = {
    size: PropTypes.string
}

export default Loading