import { createSlice } from '@reduxjs/toolkit';

function perAlert() {
}

export const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        value: {
            // values
            show: false,
            type: "success",
            title: "",
            text: "",
            question: "",
            okText: "",
            cancelText: "",
            onOk: perAlert(),
            onCancel: perAlert(),
        },
        status: 'dynamic-alert'
    },
    reducers: {
        showAlert: (state, action) => {
            state.value.show = action.show;
        },
        changeObjAlert: (state, action) => {
            state.value = {
                // values
                show: action.payload.show ?? false,
                
                type: action.payload.type ?? "success",
                
                title: action.payload.title ?? "",
                text: action.payload.text ?? "",
                question: action.payload.question ?? "",
                
                okText: action.payload.okText ?? "",
                cancelText: action.payload.cancelText ?? "",
                
                onOk: action.payload.onOk ?? perAlert(),
                onCancel: action.payload.onCancel ?? perAlert(),
            };
        }
    }
})

export const { changeObjAlert, showAlert } = alertSlice.actions;
export const selectObjAlert = (state) => state.alert.value;

export default alertSlice.reducer;