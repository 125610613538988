import React from 'react';
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom"
// 
import logo from '../../assets/images/logo-airscore.svg';
import './Header.scss';

const Header = ({ user }) => {
    const history = useHistory();

    return (
        <div className="container-header">
            <header className="header">
                <img className="logo" src={logo} onClick={() => history.push("/")} alt="airscore logo" />
                
                <p className="username">@{user.username}</p>
            </header>
        </div>
    );
}

Header.propTypes = {
    user: PropTypes.any.isRequired,
}

export default Header;