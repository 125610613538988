import PerformanceData from "./PerformanceData";

class HomeData {
    username = "";

    airscore = 0;
    performance = new PerformanceData();
    interactions = {};

    searchComplet = false;
    analyticsComplet = false;

    fromJson(data) {
        this.username = data.username ? data.username : "..."
        //
        this.searchComplet = data.airscore_data_status === "completed"
        this.analyticsComplet = data.analytics_data_status === "completed"
        // 
        this.interactions["views"] = {
            total: this._verifyTotal(data.views_total),
            average: this._calculateMedia(data.views_total, data.posts_total)
        };
        this.interactions["comments"] = {
            total: this._verifyTotal(data.comments_total),
            average: this._calculateMedia(data.comments_total, data.posts_total)
        };
        this.interactions["likes"] = {
            total: this._verifyTotal(data.likes_total),
            average: this._calculateMedia(data.likes_total, data.posts_total)
        };
        this.interactions["hashtags"] = {
            total: this._verifyTotal(data.hashtags_total),
            average: this._calculateMedia(data.hashtags_total, data.posts_total)
        };

        // 
        const localScore = parseInt(window.localStorage.getItem("airscore"));
        const scoreRamdom = Math.floor((Math.random() * 200) + 600);

        if (!localScore) {
            window.localStorage.setItem("airscore", scoreRamdom);
        }

        this.airscore = !data.score_history[0] || !data.score_history[0].value ? (!localScore ? scoreRamdom : localScore) : data.score_history[0].value;
        this.performance.atual.airscore = this.airscore;
        // 
        if (data.score_history.length > 0) {
            const lastDay = this._toDate(data.score_history[0].generated_at) ? this._toDate(data.score_history[0].generated_at) : new Date().noew().setHours(0, 0, 0, 0);

            if (lastDay)
                for (let index = 1; index < data.score_history.length; index++) {
                    const element = data.score_history[index];

                    if (index === 1) {
                        this.performance.atual.performanceAirscore = this._calculatePercentage(data.score_history[0].value, element.value);
                    }

                    const day7 = new Date(new Date().setDate(lastDay.getDate() - 7)).setHours(0, 0, 0, 0);
                    const day30 = new Date(new Date().setDate(lastDay.getDate() - 30)).setHours(0, 0, 0, 0);
                    // 
                    const day = this._toDate(element.generated_at)

                    if (day.getTime() > day30) {
                        if (day.getTime() === day7) {
                            this.performance.seven_days.airscore = element.value
                            this.performance.seven_days.performanceAirscore = this._calculatePercentage(element.value, data.score_history[index - 1].value);
                        } else if (day.getTime() === day30) {
                            this.performance.thirty_days.airscore = element.value
                            this.performance.thirty_days.performanceAirscore = this._calculatePercentage(element.value, data.score_history[index - 1].value);
                        }
                    } else {
                        return;
                    }
                }
        }

        if (data.followers_history && data.followers_history.length > 0) {
            const lastDay = this._toDate(data.followers_history[0].generated_at);

            if (lastDay)
                for (let index = 1; index < data.followers_history.length; index++) {
                    const element = data.score_history[index];

                    if (index === 1) {
                        this.performance.atual.followers = this._calculatePercentage(data.followers_history[0].value, element.value);
                    }

                    const day7 = new Date(new Date().setDate(lastDay.getDate() - 7)).setHours(0, 0, 0, 0);
                    const day30 = new Date(new Date().setDate(lastDay.getDate() - 30)).setHours(0, 0, 0, 0);
                    // 
                    const day = this._toDate(element.generated_at)

                    if (day.getTime() > day30) {
                        if (day.getTime() === day7) {
                            this.performance.seven_days.followers = this._calculatePercentage(element.value, data.score_history[index - 1].value);
                        } else if (day.getTime() === day30) {
                            this.performance.thirty_days.followers = this._calculatePercentage(element.value, data.score_history[index - 1].value);
                        }
                    } else {
                        return;
                    }
                }
        }

        return this;
    }

    _toDate(date) {
        if (date) {
            let d = date.split("/");
            let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);

            return dat;
        }

        return null;
    }

    _calculatePercentage(value, oldValue) {
        if (value && oldValue) {
            const newValue = value - oldValue;
            const change = newValue / oldValue;

            return (change * 100).toFixed(2);
        }

        return null;
    }

    _calculateMedia(itemA, itemB) {
        if(itemA.value === 0 || itemB.value === 0) return 0;

        if (itemA && itemB && (itemA.value != null && itemB.value != null)) {
            return itemA.value / itemB.value;
        }

        return null;
    }

    _verifyTotal(item) {
        return (item && item.value != null) ? item.value : null;
    }
}

export default HomeData;