const pt = { 
    // views: "Visualizações",
    // comments: "Comentários",
    // likes: "Curtidas",
    // hashtags: "Hashtags",

    // views_total: "Total de visualizações",
    // views_average: "Média de visualizações",
    // comments_total: "Total de comentários",
    // comments_average: "Média de comentários",
    // likes_total: "Total de curtidas",
    // likes_average: "Média de curtidas",
    // hashtags_total: "Total de hashtags",
    // hashtags_average: "Média de hashtags",


    views: "Views",
    comments: "Comments",
    likes: "Likes",
    hashtags: "Hashtags",

    views_total: "Total views",
    views_average: "Average views",
    comments_total: "Total comments",
    comments_average: "Average comments",
    likes_total: "Total likes",
    likes_average: "Average of likes",
    hashtags_total: "Total hashtags",
    hashtags_average: "Average hashtags",
}

export default pt;